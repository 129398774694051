<template>
  <div class="msg_wrap">
    <div class="msg_list">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>消息列表</span>
        </div>
        <el-collapse @change="handleChange" accordion>
          <el-collapse-item v-for="item in list" :key="item.id" :name="item.id">
            <template slot="title">
              <el-badge is-dot v-if="item.isUserRead" type="info"></el-badge>
              <el-badge is-dot v-else></el-badge>
              {{item.title}}
            </template>
            <div class="info_box" v-html="item.description"></div>
            <el-tag size="mini" type="info">{{item.createAt}}</el-tag>
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        layout="total, sizes, prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { moment_set } from "@/assets/js/validate";
export default {
  data() {
    return {
      info: {
        pageIndex: 1,
        pageSize: 10
      },
      list: [],
      total: 0,
      currentPage: 1
    };
  },
  computed: {
    // ...mapGetters(["msg"])
  },
  watch: {
    info: {
      handler(v) {
        this.get_msg();
      },
      deep: true
    }
  },
  methods: {
    handleChange(val) {
      let that = this;
      if (!val) return false;
      for (const item of this.list) {
        if (item.id === val && !item.isUserRead) {
          that.$api.messages_post({ id: val }).then(res => {
            if (res.errcode === 0) {
              item.isUserRead = !item.isUserRead;
              // 重新请求未读消息数量
              that.$api.users().then(res => {
                if (res.errcode === 0) {
                  that.$store.commit("set_user", res.data);
                  return;
                }
              });
            }
          });
        }
      }
    },
    get_msg() {
      this.list = [];
      this.$api.messages(this.info).then(res => {
        if (res.errcode === 0) {
          res.data.list.forEach(e => {
            this.list.push({
              isUserRead: e.isUserRead,
              title: e.title,
              id: e._id,
              description: e.description,
              createAt: moment_set(e.createAt)
            });
          });
          this.total = res.data.totalCount;
        }
      });
    },
    handleSizeChange(e) {
      this.currentPage = 1;
      this.info.pageIndex = 1;
      this.info.pageSize = e;
    },
    handleCurrentChange(v) {
      this.info.pageIndex = v;
    }
  },
  created() {
    this.get_msg();
  }
};
</script>
<style lang="scss" >
.msg_wrap {
  width: 1000px;
  margin: auto;
  margin-top: 50px;

  .msg_list {
    width: 100%;
    margin-bottom: 100px;
    .box-card {
      margin-bottom: 20px;
    }
    .el-card__header {
      border-bottom: 0;
    }
    .info_box {
      padding-right: 100px;
      margin-bottom: 10px;
    }
  }
}
</style>
